.popup {
  display: block;
  opacity: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  z-index: 10000;
  padding: 40px 0;
  background: $popup-back;
  text-align: center;
  transition: opacity 500ms ease-in-out;

  &_opened {
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  &_hide {
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &__wrap {
    position: relative;
    z-index: 2;
    display: inline-block;
    margin: 0 auto;
  }

  &__content {
    display: none;
    text-align: left;
  }

  &__close {
    position: absolute;
    top: 0;
    left: 50%;
    width: 42px;
    height: 42px;
    border: 2px solid #a1a1a2;
    border-radius: 100%;
    text-indent: -5000px;
    cursor: pointer;
    -webkit-transform: translateX( -50% );
    transform: translateX( -50% );

    &:after,
    &:before {
      position: absolute;
      top: 46%;
      left: 8px;
      right: 8px;
      height: 2px;
      background: #a1a1a2;
      transform-origin: center;
      content: '';
    }
    &:after {
      transform: translateY( 50% ) rotate( 45deg );
    }
    &:before {
      transform: translateY( 50% ) rotate( -45deg );
    }

    &:hover {
      opacity: 0.8;
    }

    &:active {
      background-position: 0 bottom;
    }
  }

  &__scrollbar-measure {
    overflow: scroll;
    position: absolute;
    top: -9999px;
    width: 100px;
    height: 100px;
  }

}
.video-popup {
  width: 300px;
  height: 280px;
  padding-top: 80px;

  iframe {
    width: 100%;
    height: 100%;
  }

}
.gallery-popup {
  width: 95vw;
  height: 90vh;
  padding: 60px 30px 0;

  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .swiper-pagination-bullets {
    bottom: 22px;

    .swiper-pagination-bullet {
      opacity: 1;
      width: 12px;
      height: 12px;
      background: transparent;
      border: 2px solid #fff;
    }
    .swiper-pagination-bullet-active {
      background: #fff;
    }

  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    z-index: 3;
    top: calc(50% + 40px);
    left: 0;
    width: 30px;
    height: 70px;
    margin: 0;
    background: transparent;
    text-indent: -9999px;
    border: none;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    &:after {
      position: absolute;
      top: 35%;
      left: 65%;
      width: 20px;
      height: 20px;
      border-top: 2px solid #e09f38;
      border-left: 2px solid #e09f38;
      -webkit-transform: rotate(-45deg) translateY(-50%) translateX(-50%);
      transform: rotate(-45deg) translateY(-50%) translateX(-50%);
      -webkit-transform-origin: center;
      transform-origin: center;
      content: '';
      -webkit-transition: border 0.3s ease;
      transition: border 0.3s ease;
    }

    &:hover {

      &:after {
        border-top-color: #fff;
        border-left-color: #fff;
      }

    }

    &.swiper-button-disabled {
      opacity: 0;
      background: transparent;
    }

  }
  .swiper-button-next {
    left: auto;
    right: 0;
    -webkit-transform: scale( -1, 1 ) translateY(-50%);
    transform: scale( -1, 1 ) translateY(-50%);
  }

}
.invest-project {
  width: 280px;
  padding: 30px 20px;
  background: #fff;
  color: #29251e;
  font-size: 14px;
  line-height: 24px;
  @include main-font1();

  p {
    margin: 0 0 52px;
    text-align: justify;
  }

}

@media (min-width: $phone-landscape) {

  .video-popup {
    width: 460px;
    height: 380px;

  }
  .invest-project {
    padding: 40px 40px;
    width: 440px;
  }

}
@media (min-width: $tablet-portrait) {

  .video-popup {
    width: 740px;
    height: 580px;
  }
  .invest-project {
    width: 570px;
    padding: 50px 50px;
  }

}
@media (min-width: $tablet-landscape) {

  .video-popup {
    width: 860px;
    height: 640px;
  }

}
@media (min-width: $desktop) {

  .video-popup {
    width: 828px;
    height: 658px;
  }
  .gallery-popup {
    padding: 80px 80px 0;

    .swiper-slide {
      background-size: contain;
    }
    .swiper-button-next,
    .swiper-button-prev {
      width: 70px;
      height: 70px;

    }
    .swiper-button-next {
      left: auto;
      right: 0;
      -webkit-transform: scale( -1, 1 ) translateY(-50%);
      transform: scale( -1, 1 ) translateY(-50%);
    }

  }
  .invest-project {
    padding: 72px 100px 81px;
    font-size: 18px;
    line-height: 28px;
  }

}
@media (min-width: $desktop-wide) {

  .video-popup {
    width: 1028px;
    height: 658px;
  }

}