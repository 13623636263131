//fonts
@mixin main-font1() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin main-font2() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 500;
  font-style: normal;
}
@mixin main-font3() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 600;
  font-style: normal;
}
@mixin main-font4() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin main-font5() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
  font-style: normal;
}
@mixin main-font6() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin main-font7() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 900;
  font-style: normal;
}
@mixin main-font8() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 400;
  font-style: normal;
}
@mixin main-font9() {
  font-family: 'Libre Franklin', sans-serif;
  font-weight: 300;
  font-style: normal;
}

//@mixin main-font6() {
//  font-family: "Nexa Bold", sans-serif;
//  font-weight: 300;
//  font-style: normal;
//}
//@mixin main-font7() {
//  font-family: "Nexa Heavy", sans-serif;
//  font-weight: 300;
//  font-style: normal;
//}
//@mixin main-font8() {
//  font-family: "Nexa Regular", sans-serif;
//  font-weight: 300;
//  font-style: normal;
//}
//@mixin main-font9() {
//  font-family: "Nexa Light", sans-serif;
//  font-weight: 300;
//  font-style: normal;
//}

@mixin justify() {
  text-align: justify;

  &:after {
    display: inline-block;
    width: 100%;
    content: '';
  }
}

@mixin absoluteCenter() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50% );
}
@mixin absoluteCenterX() {
  position: absolute;
  left: 50%;
  transform: translateX( -50% );
}
@mixin absoluteCenterY() {
  position: absolute;
  top: 50%;
  transform: translateY( -50% );
}
