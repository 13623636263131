.single-gallery {
  position: relative;
  width: 100%;
  padding: 50px 0 80px;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 141px;
    background: #f6f8fa;
    content: '';
  }

}
.single-gallery__container {
  width: 100%;
  height: 300px;
  cursor: pointer;

  &:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
  }

  &:hover {

    &:before {
      -webkit-animation: shine .75s;
      animation: shine .75s;
    }
  }

  @-webkit-keyframes shine {
    100% {
      left: 125%;
    }
  }
  @keyframes shine {
    100% {
      left: 125%;
    }
  }

  .swiper-slide {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
  }
  .swiper-pagination-bullets {
    bottom: 22px;

    .swiper-pagination-bullet {
      opacity: 1;
      width: 12px;
      height: 12px;
      background: transparent;
      border: 2px solid #fff;
    }
    .swiper-pagination-bullet-active {
      background: #fff;
    }

  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 20px;
    width: 46px;
    height: 46px;
    margin: -23px 0 0 0;
    background: transparent;
    text-indent: -9999px;
    border: none;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 46px;
      height: 46px;
      border-radius: 100%;
      background: #ffffff;
      content: '';
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
    }

    &:after {
      position: absolute;
      top: 35%;
      left: 62%;
      width: 13px;
      height: 13px;
      border-top: 1px solid #e09f38;
      border-left: 1px solid #e09f38;
      -webkit-transform: rotate(-45deg) translateY(-50%) translateX(-50%);
      transform: rotate(-45deg) translateY(-50%) translateX(-50%);
      -webkit-transform-origin: center;
      transform-origin: center;
      content: '';
      -webkit-transition: border 0.3s ease;
      transition: border 0.3s ease;
    }

    &:hover {

      &:before {
        background: #e09f38;
      }
      &:after {
        border-top-color: #fff;
        border-left-color: #fff;
      }

    }

    &.swiper-button-disabled {
      opacity: 0;
      background: transparent;
    }

  }
  .swiper-button-next {
    left: auto;
    right: 20px;
    -webkit-transform: scale( -1, 1 );
    transform: scale( -1, 1 );
  }

}
.single-gallery__layout {
  position: relative;
  z-index: 1;
  max-width: 770px;
  //padding: 0 20px;
  margin: 0 auto;
}

@media (min-width: $tablet-portrait) {

  .single-gallery {
    padding: 20px 0 80px;
  }
  .single-gallery__container {
    height: 370px;
  }

}
/*@media (min-width: $desktop) {

  .single-gallery {

    .swiper-button-next,
    .swiper-button-prev {
      width: 100px;

      &:after {
        left: 49%;

      }

    }

  }

}*/


