.btn {
  overflow: hidden;
  @include inline-flex();
  @include flex-direction(row);
  @include align-items(center);
  @include justify-content(center);
  border: none;
  min-width: 158px;
  height: 40px;
  padding: 1px 24px 0;
  color: #fff;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  background: #e09f38;
  @include main-font4();
  -webkit-transition: border 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  transition: border 0.3s ease, color 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-shadow: 0 0 15px 2px rgba(224, 159, 56, 0.34);

  &:hover {
    background: rgba(41, 37, 30, 1);
    color: #fff;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.3);

  }
  &:active {
    background: #000;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin: -3px 14px 0 -5px;
    width: 30px;
    height: 30px;
    -webkit-transition: fill 0.3s ease;
    transition: fill 0.3s ease;
  }

}
.btn_full {
  width: 100%;
}
.btn_2 {
  vertical-align: middle;
  width: 215px;
  height: 55px;
  border-radius: 55px;
  border: 2px solid #fff;
  box-shadow: none;
  background: #fff;
  color: #29251e;

  &:hover {
    color: #fff;
    background: #e09f38;
    box-shadow: none;
  }
  &:active {
    background: #c78a34;
  }


}
.btn_3 {
  vertical-align: middle;
  min-width: 280px;
  height: 55px;
  border-radius: 55px;
  border: 2px solid #fff;
  box-shadow: none;
  background: transparent;
  color: #fff;

  &:hover {
    color: #fff;
    border-color: #e09f38;
    background: #e09f38;
    box-shadow: none;
  }
  &:active {
    color: #29251e;
    border-color: #fff;
    background: #fff;
  }


}
.btn_4 {
  vertical-align: middle;
  min-width: 215px;
  height: 55px;
  border-radius: 55px;
  border: 2px solid #e09f38;
  box-shadow: none;
  background: #fff;
  color: #29251e;
  font-size: 13px;
  line-height: 13px;

  &:hover {
    color: #fff;
    background: #e09f38;
    border-color: #e09f38;
    box-shadow: 0 3px 10px 2px rgba(224, 159, 56, 0.34);
  }
  &:active {
    background: #c78a34;
    border-color: #c78a34;
  }

}
.btn_5 {
  background: #e09f38;

  &:hover {
    background: #fff;
    color: #000;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.3);
  }
  &:active {
    background: #fff;
  }

}
.btn_6 {
  vertical-align: middle;
  width: auto;
  min-width: 220px;
  height: 55px;
  border-radius: 55px;
  border: 2px solid #e09f38;
  box-shadow: none;
  background: transparent;
  color: #fff;

  &:hover {
    color: #fff;
    background: #e09f38;
    border-color: #e09f38;
    box-shadow: 0 3px 10px 2px rgba(224, 159, 56, 0.34);
  }
  &:active {
    color: #29251e;
    background: #fff;
    border-color: #fff;
    box-shadow: 0 3px 10px 2px rgba(255, 255, 255, 0.34);
  }


}
.btn_7 {
  &:hover {
    background: #fff;
    color: #000;
    box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.3);

    svg {
      fill: #000;
    }

  }
  &:active {
    background: #fff;
  }
}
button.btn {

  >span {
    width: 100%;
    text-align: center;
  }

}
.btn_play {

  svg {
    fill: #e2a544;
  }

  &:hover {

    svg {
      fill: #fff;
    }

  }
  &:active {

    svg {
      fill: #fff;
    }

  }


}
.btn_discover {

  svg {
    fill: #fff;
  }

  &:hover {

    svg {
      fill: #fff;
    }

  }
  &:active {

    svg {
      fill: #29251e;
    }

  }


}
.btn_send {

  svg {
    width: 30px;
    height: 32px;
    margin: -3px -5px 0 14px;
    fill: #fff;
  }

}

@media (min-width: $desktop) {

  .btn {
    height: 61px;
  }
  .btn_2 {
    height: 55px;
    width: 200px;
    border: 2px solid #e09f38;
    box-shadow: none;
    background: #fff;
    color: #29251e;

    &:hover {
      color: #fff;
      background: #e09f38;
      border-color: #e09f38;
      box-shadow: 0 3px 10px 2px rgba(224, 159, 56, 0.34);
    }
    &:active {
      background: #c78a34;
      border-color: #c78a34;
    }


  }
  .btn_3 {
    height: 55px;
  }
  .btn_4 {
    height: 55px;
    font-size: 15px;
    line-height: 15px;
  }
  .btn_6 {
    height: 55px;
    box-shadow: none;
  }

}
@media (min-width: $desktop-wide) {

  .btn_2 {
    width: 215px;
  }

}